<!--
Copyright ThreatBlockr Inc 2022
Created by ejohnson on 8/31/22
-->
<template>
  <v-card class="pa-6"
          elevation="0">

    <!--
    <v-card-title :class="{ 'error&#45;&#45;text': errors.length > 0}"
                  class="text-h5 justify-center gray&#45;&#45;text">

      <slot name="noDataString">
        {{ computedNoDataString }}
      </slot>
    </v-card-title>
    -->

    <slot name="noDataString">
      <v-card-title :class="{ 'error--text': errors.length > 0, 'gray--text': errors.length === 0, }"
                    class="text-h5 justify-center">
        {{ computedNoDataString }}
      </v-card-title>
    </slot>

    <!--
    <v-card-text class="text-center">
      <slot name="explanation">
        {{ explanation }}
      </slot>
    </v-card-text>
    -->

    <slot name="explanation">
      <v-card-text v-if="explanation !== null"
                   class="text-center">{{ explanation }}
      </v-card-text>
    </slot>
    <!--
    <slot name="explanation">
      <v-card-text v-if="explanation !== null"
                   class="text-center">{{ explanation }}
      </v-card-text>
    </slot>
    -->

    <!--
    <v-card-title :class="{ 'error&#45;&#45;text': errors.length > 0}"
                  class="text-h5 justify-center gray&#45;&#45;text">
      {{ computedNoDataString }}
    </v-card-title>
    -->

    <!--
    <v-card-text v-if="explanation !== null"
                 class="text-center">{{ explanation }}
    </v-card-text>
    -->

  </v-card>
</template>

<script>
  const ENTITY_DISPLAY_NAME = 'BSTableNoData'

  export default {
    name: ENTITY_DISPLAY_NAME,
    props: {
      itemLabel: {
        type: String,
        default: 'Items',
      },
      noDataString: {
        type: String,
        default: null,
      },
      explanation: {
        type: String,
        default: null,
      },
      errors: {
        type: Array,
        default: function() {
          return []
        },
      },
    },
    components: {},
    mixins: [],
    data() {
      return {
        entityDisplayName: ENTITY_DISPLAY_NAME,
        items: [],
      }
    },
    watch: {},
    computed: {
      computedNoDataString: function() {
        if (this.noDataString) {
          return this.noDataString
        } else {
          return `No ${ this.itemLabel } to Display`
        }
      },
    },
    methods: {},
    beforeCreate() {
    },
    created() {
    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
