<!--
  - Copyright ThreatBlockr Inc 2023-2024
  - ejohnson created 1/7/23
  -->
<template>
  <!--<div class="title-block d-flex flex-column justify-center fill-width"-->
  <!--     style="">-->
  <div class="title-block d-flex flex-column fill-width"
       style="">

    <div class="title-block-top d-flex align-center fill-width"
         :class="computedTitleBlockClasses.top.classes">

      <div class="d-flex flex-basis-0 align-baseline flex-gap-2 flex-grow-1">

        <template v-if="hasPrependSlot">
          <slot name="prepend"/>
        </template>

        <div :class="computedTitleBlockClasses.title">
          <slot name="title">{{ title }}</slot>
        </div>

        <template v-if="hasAppendSlot">
          <slot name="append"/>
        </template>

        <div v-if="count"
             class="align-self-end text-subtitle-1 gray--text nowrap">({{ count | localeString }})
        </div>

        <slot name="subtitle">
          <div v-if="subtitle"
               class="align-self-baseline text-subtitle-1 gray--text nowrap">{{ subtitle }}
          </div>
        </slot>

        <div v-if="hasTopLeftSlot"
             class="d-flex align-self-center flex-grow-1">
          <slot name="topLeft"/>
        </div>

      </div>

      <div v-if="hasTopRightSlot" class="d-flex flex-basis-0 align-center flex-grow-1 justify-end">
        <slot name="topRight"/>
      </div>
    </div>

    <div v-if="hasBottomSlots"
         class="title-block-bottom d-flex align-center"
         :class="computedTitleBlockClasses.bottom.classes"
         style="min-height: 4rem;">
      <div v-if="hasBottomLeftSlot" class="d-flex flex-basis-0 align-center flex-grow-1">
        <slot name="bottomLeft"/>
      </div>
      <div v-if="hasBottomRightSlot" class="d-flex flex-basis-0 align-center flex-grow-1 justify-end">
        <slot name="bottomRight"/>
      </div>
    </div>

    <div v-if="debug">
      <bs-print :value="{showTitleDivider}"/>
      <bs-print :value="{topClasses}"/>
      <bs-print :value="{computedTitleBlockClasses}"/>
      <bs-print :value="{options}"/>
    </div>

  </div>

</template>

<script>
  import { isArray, isObject, isString } from 'lodash'

  export default {
    name: 'BSViewTitle',
    props: {
      debug: {
        type: Boolean,
        default: false,
      },

      title: {
        type: String,
        required: true,
      },

      count: {
        type: [ Number, String ],
        required: false,
      },
      subtitle: {
        type: String,
        required: false,
      },

      options: {
        type: Object,
        default(rawProps) {
          return {
            top: {},
            bottom: {},
          }
        },
      },

      titleClass: {
        type: String,
        default: 'text-h4',
      },
      topClasses: {
        type: [ String, Object ],
        default(rawProps) {
          return {
            'pl-4': true,
            'pr-2': true,
          }
        },
      },
      bottomClasses: {
        type: [ String, Object ],
        default(rawProps) {
          return {
            'pl-4': true,
            'pr-4': true,
          }
        },
      },



      paddingClassTop: {
        type: [ String ],
        default: 'px-4 py-2',
      },
      paddingClassBottom: {
        type: String,
        default: 'px-4 py-4',
      },
      paddingClass: {
        type: String,
        default: 'px-4 py-4',
      },


      showTitleDivider: {
        type: Boolean,
        default: false,
      },
      titleDividerStyle: {
        type: String,
        default: 'dashed',
      },


      showDividerTop: {
        type: Boolean,
        default: false,
      },
      showDividerBottom: {
        type: Boolean,
        default: false,
      },
      dividerTopStyle: {
        type: String,
        default: 'dashed',
      },
      dividerBottomStyle: {
        type: String,
        default: 'dashed',
      },

    },
    components: {},
    mixins: [],
    data() {
      return {}
    },
    watch: {},
    computed: {
      computedTitleBlockClasses: function() {
        // console.log('computedTitleBlockClasses')

        let topClasses = this.options.top?.classes
        // console.log('computedTitleBlockClasses options topClasses', topClasses)

        let bottomClasses = this.options.bottom?.classes
        if (!topClasses) {
          let propTopClasses = this.topClasses
          // console.log('computedTitleBlockClasses propTopClasses', propTopClasses)

          const isPropString = isString(propTopClasses)
          const isPropArray = isArray(propTopClasses)
          const isPropObject = isObject(propTopClasses)

          // console.log('computedTitleBlockClasses propTopClasses isPropString', isPropString)
          // console.log('computedTitleBlockClasses propTopClasses isPropArray', isPropArray)
          // console.log('computedTitleBlockClasses propTopClasses isPropObject', isPropObject)

          if (isPropString) {
            // console.log('computedTitleBlockClasses topClasses type string')
            const topClassesArray = propTopClasses.split(' ')
            // console.log('computedTitleBlockClasses topClasses type string topClassesArray')
            topClasses = topClassesArray.reduce((accumulator, classString) => ({ ...accumulator, [classString]: true }), {})
            // console.log('computedTitleBlockClasses topClasses type string topClasses', topClasses)
          } else if (isPropArray) {
            // console.log('computedTitleBlockClasses topClasses type isPropArray')
            topClasses = propTopClasses.reduce((accumulator, classString) => ({ ...accumulator, [classString]: true }), {})
          } else if (isPropObject) {
            // console.log('computedTitleBlockClasses topClasses type isPropObject')
            topClasses = propTopClasses
          } else {
            console.log('computedTitleBlockClasses topClasses type other')
          }
        }
        if (!bottomClasses) {
          let propBottomClasses = this.bottomClasses
          // console.log('computedTitleBlockClasses propBottomClasses', propBottomClasses)

          const isPropString = isString(propBottomClasses)
          const isPropArray = isArray(propBottomClasses)
          const isPropObject = isObject(propBottomClasses)

          if (isPropString) {
            // console.log('computedTitleBlockClasses bottomClasses type string')
            const bottomClassesArray = propBottomClasses.split(' ')
            // console.log('computedTitleBlockClasses bottomClasses type string bottomClassesArray')
            bottomClasses = bottomClassesArray.reduce((accumulator, classString) => ({ ...accumulator, [classString]: true }), {})
            // console.log('computedTitleBlockClasses bottomClasses type string bottomClasses', topClasses)
          } else if (isPropArray) {
            // console.log('computedTitleBlockClasses bottomClasses type isPropArray')
            bottomClasses = propBottomClasses.reduce((accumulator, classString) => ({ ...accumulator, [classString]: true }), {})
          } else if (isPropObject) {
            // console.log('computedTitleBlockClasses bottomClasses type isPropObject')
            bottomClasses = propBottomClasses
          } else {
            console.log('computedTitleBlockClasses topClasses type other')
          }

          /*
           bottomClasses = {
           [this.paddingClassBottom]: true,
           }
           */
        }
        topClasses = {
          ...topClasses,
          'bb-dotted': this.showDividerTop === true && this.dividerTopStyle === 'dotted',
          'bb-dashed': this.showDividerTop === true && this.dividerTopStyle === 'dashed',
          'bb-double': this.showDividerTop === true && this.dividerTopStyle === 'double',
          'bb-double-darker': this.showDividerTop === true && this.dividerTopStyle === 'double-darker',
        }
        // console.log('computedTitleBlockClasses topClasses', topClasses)
        bottomClasses = {
          ...bottomClasses,
          'bb-dotted': this.showDividerBottom === true && this.dividerBottomStyle === 'dotted',
          'bb-dashed': this.showDividerBottom === true && this.dividerBottomStyle === 'dashed',
          'bb-double': this.showDividerBottom === true && this.dividerBottomStyle === 'double',
          'bb-double-darker': this.showDividerBottom === true && this.dividerBottomStyle === 'double-darker',
        }
        // console.log('computedTitleBlockClasses bottomClasses', bottomClasses)

        let optionsNew = {
          title: this.options.title || this.titleClass,
          top: {
            classes: topClasses,
          },
          bottom: {
            classes: bottomClasses,
          },
        }
        // console.log('computedTitleBlockClasses optionsNew', optionsNew)
        // console.log('computedTitleBlockClasses ////////////////////')

        return optionsNew
      },
      computedClassesNew: function() {
        console.log('computedClassesNew')
        const classes = {
          'bb-dotted': this.showTitleDivider && this.titleDividerStyle === 'dotted',
          'bb-dashed': this.showTitleDivider && this.titleDividerStyle === 'dashed',
          'bb-double': this.showTitleDivider && this.titleDividerStyle === 'double',
        }

        return classes
      },
      hasPrependSlot: function() {
        return this.$slots['prepend']
      },
      hasAppendSlot: function() {
        return this.$slots['append']
      },
      hasTopLeftSlot: function() {
        return this.$slots['topLeft']
      },
      hasTopRightSlot: function() {
        return this.$slots['topRight']
      },
      hasBottomLeftSlot: function() {
        return this.$slots['bottomLeft']
      },
      hasBottomRightSlot: function() {
        return this.$slots['bottomRight']
      },
      hasBottomSlots() {
        return this.hasBottomLeftSlot || this.hasBottomRightSlot
      },
    },
    methods: {},
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">


</style>
