<!--
  - Copyright ThreatBlockr Inc 2024
  - ejohnson created
  -->

<template>

  <!--
<bs-action-menu :close-on-content-click="true"
                :close-on-click="true"
>
-->

  <bs-action-menu class="column-toggle">

    <template v-slot:activator="{ on, attrs }">

      <bs-glyph-button tooltip-position="none"
                       preset="columns"
                       :plain="true"
                       :muted="true"
                       :ripple="false"
                       v-bind="attrs"
                       v-on="on"
      />
    </template>

    <v-list dense>

      <v-list-item-group v-model="computedEnabledHeaders"
                         multiple>
        <!--
        <v-list-item v-for="(item, index) in computedToggleableHeaders"
                     :key="index"
                     :value="item.value">
        -->
        <v-list-item v-for="(item, index) in computedToggleableHeaders"
                     :key="index"
                     :value="item.value">
          <template v-slot:default="{ active }">
            <v-list-item-action>
              <v-checkbox :input-value="active"
                          :ripple="false"
                          color="primary"/>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </template>
        </v-list-item>
      </v-list-item-group>

    </v-list>
  </bs-action-menu>

</template>

<script>


  import BSActionMenu from '@/components/BSActionMenu.vue'

  export default {
    name: 'BsTableHeaderToggle',
    props: {
      // proppy https://vuejs.org/guide/components/props.html#prop-validation
      // computedToggleableHeaders: {},
      value: {
        type: Array,
        default: function() {
          return []
        },
      },
      /*
       enabledColumns: {
       type: Array,
       default: function() {
       return []
       },
       },
       */
      headers: {
        type: Array,
        required: true,
      },

    },
    components: {
      'bs-action-menu': BSActionMenu,

    },
    mixins: [],
    data() {
      return {}
    },
    watch: {},
    computed: {
      computedEnabledHeaders: {
        // console.log('computedEnabledHeaders')
        // let retVal
        get() {
          console.log('computed computedEnabledHeaders get')
          return this.value
        },
        set(value) {
          console.log('computed computedEnabledHeaders set value', value)
          this.$emit('input', value)
          // this.$emit('update:enabled-headers', value)
        },
      },
      computedToggleableHeaders: function() {
        console.log('computedToggleableHeaders')
        let retVal = this.headers.filter(object => object.toggleable)
        return retVal
      },

    },
    methods: {},
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  :deep(.v-list-item__action) {
    margin: 0 !important;
    margin-right: 8px !important;
  }
</style>
