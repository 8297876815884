<!--
Copyright ThreatBlockr Inc 2023
Created by ejohnson on 3/3/23
-->

<!--TODO: https://github.com/vuetifyjs/vuetify/issues/11600#issuecomment-642273351-->
<template>
  <v-card :class="computedTableContainerClasses"
          :elevation="inline ? 0 : elevation"

          class="bs-table-container">

    <!--    begin title block-->
    <!--    if they have given a title string, use our BSViewTitle component-->

    <bs-view-title v-if="cardTitle"
                   :title="title"
                   :classes="inline ? { paddingClass: 'pa-4'} : { paddingClass: 'px-4 py-4' }"
                   :count="paginationString">
      <!--=============================================================================================-->
      <!--=============================================================================================-->
      <template v-slot:topLeft>
        <slot name="topLeft"></slot>

        <!--=============================================================================================-->
        <!--          Start AutoDiscriminators topLeft -->
        <!--=============================================================================================-->
        <template v-if="autoDiscriminators === 'topLeft'">
          <slot v-for="(header, index) in computedFilterHeaders"
                :name="header.value">
            <bs-table-unique-select v-if="header.filterType === 'select'"
                                    v-model="localDiscriminators[header.value]"
                                    :key="index"
                                    :class="{ 'ml-4': index !== 0 }"
                                    :disabled="loading"
                                    :header="header"
                                    :items="computedTableItems"
                                    :loading="loading"
                                    :multiple="header.filterType === 'selectMultiple'"
                                    :property="header.value"
                                    class="discriminator"
                                    dense
                                    hide-details
                                    outlined
                                    preserve-order
                                    select-item
                                    single-line
            />
          </slot>
          <bs-discriminator-reset v-if="computedFilterHeaders.length > 1"
                                  :discriminators="discriminators"/>
        </template>
        <!--=============================================================================================-->
        <!--          End AutoDiscriminators-->
        <!--=============================================================================================-->

      </template>
      <!--=============================================================================================-->
      <!--=============================================================================================-->

      <template v-slot:topRight>
        <bs-filter-textfield v-if="search === 'topRight'"
                             v-model="computedDefaultPagination.search"
                             class="discriminator ml-auto"
                             :disabled="items < 1"
                             debounce
                             dense
                             label="Filter"/>

        <slot name="topRight"></slot>
      </template>

    </bs-view-title>
    <!--    otherwise we roll our own with left and right cols, each with a top and bottom-->
    <div v-else>
      <!-- determine if we have either left OR right side content-->
      <div class="header-row-top d-flex justify-space-between">

        <!-- determine if we have any left side content
        slot topLeft
        autoDiscriminators === 'topLeft'
        -->
        <div class="header-col-left d-flex align-center">
          <!--=============================================================================================-->
          <!--=============================================================================================-->
          <slot name="topLeft"/>
          <!--=============================================================================================-->
          <!--=============================================================================================-->

          <!--=============================================================================================-->
          <!--          Start AutoDiscriminators topLeft -->
          <!--=============================================================================================-->
          <template v-if="autoDiscriminators === 'topLeft'">
            <slot v-for="(header, index) in computedFilterHeaders"
                  :name="header.value">
              <bs-table-unique-select v-if="header.filterType === 'select'"
                                      v-model="localDiscriminators[header.value]"
                                      :key="index"
                                      :class="{ 'ml-4': index !== 0 }"
                                      :disabled="loading"
                                      :header="header"
                                      :items="computedTableItems"
                                      :loading="loading"
                                      :multiple="header.filterType === 'selectMultiple'"
                                      :property="header.value"
                                      class="discriminator"
                                      dense
                                      hide-details
                                      outlined
                                      preserve-order
                                      select-item
                                      single-line
              />
            </slot>
            <bs-discriminator-reset v-if="computedFilterHeaders.length > 1"
                                    :discriminators="discriminators"/>
          </template>
          <!--=============================================================================================-->
          <!--          End AutoDiscriminators-->
          <!--=============================================================================================-->

        </div>

        <!-- determine if we have any right side content
        slot topRight
        search === 'topRight'
        -->
        <div class="header-col-right">
          <bs-filter-textfield v-if="search === 'topRight'"
                               v-model="computedDefaultPagination.search"
                               class="discriminator ml-4"
                               :disabled="items < 1"
                               debounce
                               dense
                               label="Filter"/>
          <!--=============================================================================================-->
          <!--=============================================================================================-->
          <slot name="topRight"/>
          <!--=============================================================================================-->
          <!--=============================================================================================-->

        </div>

      </div>

    </div>
    <!--    end title block-->

    <!--    begin header-content-->
    <!--
    <div v-if="hasHeader" class="header-content "
         :class="inline ? 'pb-4' : 'px-4 pt-4 pb-4'">


      <div v-if="!cardTitle"
           class="header-row-top d-flex justify-space-between">

        <div class="header-col-left d-flex align-center">

          &lt;!&ndash;
          <div class="d-flex align-baseline">
            <div v-if="title" class="text-h5">{{ title }}</div>

            <small v-if="titlePaginationString && paginationString"
                   class="align-self-baseline text-subtitle-2 ml-1 gray&#45;&#45;text nowrap">({{ paginationString }})
            </small>

            <div v-if="subtitle"
                 class="align-self-baseline text-subtitle-1 ml-1 gray&#45;&#45;text nowrap">{{ subtitle }}
            </div>

          </div>
          &ndash;&gt;

          &lt;!&ndash;=============================================================================================&ndash;&gt;
          &lt;!&ndash;=============================================================================================&ndash;&gt;
          <slot name="topLeft"/>
          &lt;!&ndash;=============================================================================================&ndash;&gt;
          &lt;!&ndash;=============================================================================================&ndash;&gt;

          &lt;!&ndash;=============================================================================================&ndash;&gt;
          &lt;!&ndash;          Start AutoDiscriminators topLeft &ndash;&gt;
          &lt;!&ndash;=============================================================================================&ndash;&gt;
          <template v-if="autoDiscriminators === 'topLeft'">
            <slot v-for="(header, index) in computedFilterHeaders"
                  :name="header.value">
              <bs-table-unique-select v-if="header.filterType === 'select'"
                                      :key="index"
                                      v-model="localDiscriminators[header.value]"
                                      :class="{ 'ml-4': index !== 0 }"
                                      :disabled="loading"
                                      :header="header"
                                      :items="computedTableItems"
                                      :loading="loading"
                                      :multiple="header.filterType === 'selectMultiple'"
                                      :property="header.value"
                                      class="discriminator"
                                      dense
                                      hide-details
                                      outlined
                                      preserve-order
                                      select-item
                                      single-line
              />
            </slot>
            <bs-discriminator-reset v-if="computedFilterHeaders.length > 1"
                                    :discriminators="discriminators"/>
          </template>
          &lt;!&ndash;=============================================================================================&ndash;&gt;
          &lt;!&ndash;          End AutoDiscriminators&ndash;&gt;
          &lt;!&ndash;=============================================================================================&ndash;&gt;
        </div>

        <div class="header-col-right">
          <bs-filter-textfield v-if="search === 'topRight'"
                               v-model="computedDefaultPagination.search"
                               class="discriminator ml-4"
                               :disabled="items < 1"
                               debounce
                               dense
                               label="Filter"/>
          &lt;!&ndash;=============================================================================================&ndash;&gt;
          &lt;!&ndash;=============================================================================================&ndash;&gt;
          <slot name="topRight"/>
          &lt;!&ndash;=============================================================================================&ndash;&gt;
          &lt;!&ndash;=============================================================================================&ndash;&gt;

        </div>
      </div>

      &lt;!&ndash;      <div class="header-row-bottom d-flex justify-space-between">&ndash;&gt;

    </div>
    -->
    <div v-if="hasHeader" class="header-content "
         :class="inline ? 'px-4 pb-4' : 'px-4 pb-4 pt-4'">
      <div class="header-row-bottom">
        <div class="header-col-left">
          <!--=============================================================================================-->
          <!--=============================================================================================-->
          <slot name="bottomLeft"/>
          <!--=============================================================================================-->
          <!--=============================================================================================-->

          <!--=============================================================================================-->
          <!--          Start AutoDiscriminators bottomLeft -->
          <!--=============================================================================================-->
          <template v-if="autoDiscriminators === 'bottomLeft'">
            <div class="discriminators">
              <slot v-for="(header, index) in computedFilterHeaders"
                    :name="header.value">
                <bs-table-unique-select v-if="header.filterType === 'select'"
                                        v-model="localDiscriminators[header.value]"
                                        :key="index"
                                        :disabled="loading || items.length === 0"
                                        :header="header"
                                        :items="computedTableItems"
                                        :loading="loading"
                                        :multiple="header.filterType === 'selectMultiple'"
                                        :menu-props="{ offsetY: true }"
                                        :property="header.value"
                                        class="discriminator bs-table-unique-select"
                                        :sticky="sticky"
                                        dense
                                        hide-details
                                        outlined
                                        preserve-order
                                        select-item
                                        single-line
                >
                </bs-table-unique-select>

                <!--                :popup-style="{ bottom: 0, top: 'auto', left: 0, marginBottom: '50px' }"-->

                <!--
                                <bs-datetime-range-picker v-else-if="header.filterType === 'dateRange'"
                                                          v-model="localDiscriminators[header.value]"
                                                          :confirm="true"
                                                          :inline="false"
                                                          :separate-date="true"

                                                          :disabled="loading || items.length === 0"

                                                          :clearable="true"
                                                          :sticky="false"

                                                          class="discriminator bs-table-daterange"
                                                          hide-details="auto"
                                                          placeholder="All Dates"

                                                          :between-dates-func="header._betweenDatesFunction"
                                                          :between-times-func="header._betweenTimesFunction"
                                                          :items="computedTableItems"/>
                -->
                <bs-datetime-range-picker v-else-if="header.filterType === 'dateRange'"
                                          v-model="localDiscriminators[header.value]"
                                          :confirm="true"
                                          :inline="false"
                                          :separate-date="true"

                                          :text-field-props="{ dense: true }"

                                          :disabled="loading || items.length === 0"

                                          :clearable="true"
                                          :sticky="false"

                                          class="discriminator bs-table-daterange"
                                          hide-details="auto"
                                          placeholder="All Dates"

                                          :items="computedTableItems"/>

              </slot>
              <bs-discriminator-reset v-if="computedFilterHeaders.length > 1"
                                      :discriminators="discriminators"/>
            </div>
          </template>
          <!--=============================================================================================-->
          <!--          End AutoDiscriminators-->
          <!--=============================================================================================-->
        </div>

        <div class="header-col-right">
          <bs-filter-textfield v-if="search === 'bottomRight'"
                               v-model="computedDefaultPagination.search"
                               class="discriminator ml-4"
                               :disabled="items < 1"
                               debounce
                               dense
                               label="Filter"
          />
          <!--==============================-->
          <!--==============================-->
          <slot name="bottomRight"/>
          <!--==============================-->
          <!--==============================-->
        </div>
      </div>

    </div>
    <!--    end header-content-->
    <!--<v-divider style="border-style: dotted"></v-divider>-->
    <!--
        resizable cols
        https://github.com/spacebnd/vuetify-data-table-resizable-colum
    -->
    <v-data-table v-model="selectedTableItems"
                  ref="bsTable"
                  :items="computedTableItems"
                  :loading="loading"
                  :headers="computedHeaders"
                  :search="computedDefaultPagination.search"
                  :show-expand="showExpand"
                  :show-select="showSelect"
                  :disable-pagination="paginate === false"
                  expand-icon="mdi-chevron-right"
                  :class="computedTableClasses"

                  :elevation="elevation"

                  :header-props="{ sortIcon: sortIcon }"
                  :footer-props="footerProps"

                  :items-per-page="computedDefaultPagination.perPage"
                  :page.sync="computedDefaultPagination.page"
                  :sort-by="computedDefaultPagination.sortColumn"
                  :sort-desc="computedDefaultPagination.sortDirection === 'descending'"

                  hide-default-footer
                  hide-default-header

                  v-bind="computedAttrs"
                  v-on="$listeners"

                  @pagination="paginationDidChange"
                  @page-count="computedDefaultPagination.pages = $event"

                  @update:sort-by="sortChanged($event, 'sortColumn')"
                  @update:sort-desc="sortChanged($event, 'sortDirection')"
                  @update:options="optionsDidChange"
    >
      <!--=======================================================================================================-->
      <!--=======================================================================================================-->

      <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="slotData">
        <slot :name="slot" v-bind="{ ...slotData }"/>
      </template>

      <!-- pass through scoped slots -->
      <!--
            <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
              <slot :name="scopedSlotName" v-bind="slotData"/>
            </template>
      -->

      <!-- pass through normal slots -->
      <!--
            <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
              <slot :name="slotName"/>
            </template>
      -->

      <!--=======================================================================================================-->
      <!--=======================================================================================================-->
      <template v-slot:progress>
        <slot name="progress">
        </slot>
      </template>

      <template v-slot:loading>
        <slot name="loading">
          <v-card class="pa-6" elevation="0">
            <v-card-title class="text-h5 justify-center gray--text">
              {{ loadingString }}
            </v-card-title>
          </v-card>
        </slot>
      </template>

      <template v-slot:no-data>
        <bs-table-no-data v-bind="bsTableNoDataProps"/>
      </template>

      <template v-slot:no-results>
        <v-card class="pa-6" elevation="0">
          <v-card-title class="text-h5 justify-center grey--text">No Matching Results</v-card-title>
        </v-card>
      </template>
      <!--=======================================================================================================-->
      <!--=======================================================================================================-->
      <!-- https://v2.vuetifyjs.com/en/api/v-data-table/#slots-header -->
      <template v-slot:header="{ props }">

        <thead>

        <tr v-if="superHeaders"
            class="header-row super-header-row">
          <th v-for="(header, index) in superHeaders"
              :key="`${ header.text }-${ index }-super-headers`"
              :class="header.class"
              :colspan="header.colspan">
            <span class="header-text" style="vertical-align: middle">{{ header.text }}</span>

          </th>
        </tr>
        <tr class="header-row" :class="{ disabled: loading || items < 1 }">
          <!--          https://v2.vuetifyjs.com/en/api/v-data-table/#props-headers-->
          <th v-if="showExpand" class="min" style=""></th>

          <th v-if="computedShowSelect"
              class="min">
            <v-simple-checkbox v-if="computedShowSelectAll"
                               v-model="selectAllCheckboxes"
                               :disabled="loading"
                               :indeterminate="isIndeterminate"
                               class="pr-4"
                               color="blue"
                               @click="btnSelectAll"
            />

          </th>

          <template v-for="header in computedHeaders">
            <th v-if="header.sortable"
                :key="`${header.value}-headers`"
                :class="header.class"
                @click="setSortColumn(header)">

              <div class="header-wrapper sort-header">
                <span class="header-text" style="vertical-align: middle">{{ header.text }}</span>
                <v-icon v-if="header.sortable"
                        class="header-sort-icon"
                        small
                        style="font-size: 19px; margin-left: 4px">
                  {{ getSortIcon(header) }}
                </v-icon>
              </div>

            </th>

            <th v-else-if="hasContentForSlotName('tableActions') === true &&
                  ((computedHasActionColumn === true &&
                    header.value === 'actions') ||
                    header.value === 'tableActionHeader')"
                :key="`${header.value}-headers`"
                :class="header.class"
                class="table-actions">
              <!--==============================-->
              <!--==============================-->
              <slot name="tableActions">
                <v-icon>mdi-dots-vertical</v-icon>
              </slot>
              <!--==============================-->
              <!--==============================-->
            </th>

            <th v-else :key="`${header.value}-headers`" :class="header.class">
              <div class="header-wrapper">
                <span class="header-text" style="vertical-align: middle">{{ header.text }}</span>
              </div>
            </th>
          </template>
        </tr>

        <tr v-if="hasColumnDiscriminators()" class="discriminator-row">
          <!--          https://v2.vuetifyjs.com/en/api/v-data-table/#props-headers-->
          <th v-if="showExpand" class="min"></th>
          <th
              v-for="header in computedHeaders"
              :key="`${header.value}-discriminators`"
              :class="header.class"
              class="py-0 pb-2"
              style=""
          >
            <slot v-if="header.filterable" :name="header.value"></slot>
          </th>
        </tr>

        </thead>
      </template>

      <!--=======================================================================================================-->
      <!--=======================================================================================================-->

      <template v-if="paginate" v-slot:footer>
        <v-divider style="border-top: 3px double rgb(0 0 0 / 12%)"></v-divider>
        <v-card-actions class="bs-table-footer">

          <bs-pagination v-show="items.length > 0"
                         v-bind.sync="computedDefaultPagination"
                         :filtered-total="filteredPagination.itemsLength"
                         :loading="loading"
                         :all-item="bsTablePaginationProps.allItem"
                         :debug="false"
                         class="nowrap ml-auto"
                         hide-first-last
                         pagination-style="buttons"
                         @changed:paginationString="setPaginationString"/>

        </v-card-actions>
      </template>

      <!--=======================================================================================================-->
      <!--=======================================================================================================-->
    </v-data-table>

    <!--
    <v-row v-if="debug && computedIsDevelopment">
      <v-col>
        <bs-print :value="{ sticky }"/>
        <bs-print :value="{ localDiscriminators }"/>
        <bs-print :value="{ discriminators }"/>
      </v-col>
      <v-col>
        <bs-print :value="{ defaultPagination }"/>
        <bs-print :value="{ computedDefaultPagination }"/>
        <bs-print :value="{ bsTablePaginationProps }"/>
      </v-col>
      <v-col>
        <bs-print :value="{ filteredPagination }"/>
      </v-col>
      <v-col>
        <bs-print :value="{ selectedTableItems }"/>
      </v-col>
    </v-row>
    -->

  </v-card>
</template>

<script>
  import PermissionsMixin from '@/mixins/PermissionsMixin.js'
  import BSPagination from '@/components/BSPagination.vue'
  import BSTableNoData from '@/components/BSTableNoData.vue'
  import BSFilterTextfield from '@/components/BSFilterTextfield.vue'
  import BSDiscriminatorReset from '@/components/BSDiscriminatorReset.vue'
  import BSViewTitle from '@/components/BSViewTitle.vue'

  import BSTableUniqueSelect from '@/components/select/BaseSelect/Unique/BSTableUniqueSelect.vue'
  import BSDatetimeRangePicker from '@/views/unexpectedBlocks/BSDatetimeRangePicker.vue'
  import BSDatetimeRangePickerMixin from '@/mixins/BSDatetimeRangePickerMixin.js'
  import { formatISO, isValid, parseISO } from 'date-fns'

  export default {
    name: 'BSTable',
    props: {
      // https://vuejs.org/guide/components/props.html#prop-validation

      // start card props =====================================================
      // start card props =====================================================
      // start card props =====================================================
      inline: {
        type: Boolean,
        default: false,
      },
      elevation: {
        type: Number,
      },

      // end card props =====================================================
      // end card props =====================================================
      // end card props =====================================================


      // start title props =====================================================
      // start title props =====================================================
      // start title props =====================================================
      cardTitle: {
        type: Boolean,
        default: true,
      },
      bsViewTitleProps: {
        /*
         cardTitle
         title
         hasHeader
         paginationString
         subtitle
         */
        type: Object,
        default(rawProps) {
          return {
            title: null,
            subtitle: null,
            paddingClass: 'pt-4 pr-4 pb-4 pl-4',
            count: this.paginationString,
          }
        },
      },
      title: {
        type: String,
      },
      subtitle: {
        type: String,
      },
      titlePaginationString: {
        type: Boolean,
        default: false,
      },
      hasHeader: {
        type: Boolean,
        default: true,
      },

      // end title props =====================================================
      // end title props =====================================================
      // end title props =====================================================


      // start table props =====================================================
      // start table props =====================================================
      // start table props =====================================================
      superHeaders: {
        type: Array,
      },
      search: {
        type: String,
        validator(value) {
          // The value must match one of these strings
          return [ 'topRight', 'bottomRight', 'none' ].includes(value)
        },
      },
      discriminators: {
        type: Object,
        default: null,
      },
      sticky: {
        type: Boolean,
        default: true,
      },
      autoDiscriminators: {
        type: String,
        default: 'topLeft',
        validator(value) {
          // The value must match one of these strings
          return [ 'topLeft', 'bottomLeft' ].includes(value)
        },
      },

      tableProps: {
        /*
         cardTitle
         title
         hasHeader
         paginationString
         subtitle
         */
        type: Object,
        default(rawProps) {
          return {
            // title: null,
            // subtitle: null,
            // paddingClass: 'pt-4 pr-4 pb-4 pl-4',
            // count: this.paginationString,
          }
        },
      },
      bsTableNoDataProps: {
        type: Object,
        default(rawProps) {
          return {
            allItem: true,
          }
        },
      },
      loading: {
        type: Boolean,
        default: false,
      },
      loadingString: {
        type: String,
        default: 'Loading...',
      },
      showExpand: {
        type: Boolean,
        default: false,
      },
      showSelect: {
        type: Boolean,
        default: false,
      },
      showSelectAll: {
        type: Boolean,
        default: true,
      },

      value: {
        default: undefined,
      },
      items: {
        type: Array,
        default(rawProps) {
          return []
        },
        required: true,
      },
      headers: {
        type: Array,
        required: true,
      },
      highlightSort: {
        type: Boolean,
        default: true,
      },
      roleHeaders: {
        type: Boolean,
        default: true,
      },
      rowHover: {
        type: Boolean,
        default: true,
      },
      altRows: {
        type: Boolean,
        default: false,
      },

      // end table props =====================================================
      // end table props =====================================================
      // end table props =====================================================


      // start pagination props =====================================================
      // start pagination props =====================================================
      // start pagination props =====================================================
      paginationProps: {
        /*
         paginate
         bsTablePaginationProps.allItem
         debug
         */
        type: Object,
        default(rawProps) {
          return {
            // title: null,
            // subtitle: null,
            // paddingClass: 'pt-4 pr-4 pb-4 pl-4',
            // count: this.paginationString,
          }
        },
      },
      bsTablePaginationProps: {
        type: Object,
        default(rawProps) {
          return {
            allItem: true,
          }
        },
      },
      paginate: {
        type: Boolean,
        default: true,
      },

      // end pagination props =====================================================
      // end pagination props =====================================================
      // end pagination props =====================================================


      debug: {
        type: Boolean,
        default: false,
      },


      options: {
        type: Object,
        default(rawProps) {
          return {
            // page: 1,
            sortBy: [],
            // sortDesc: false,
            mustSort: false,
          }
        },
      },



    },
    components: {
      'bs-view-title': BSViewTitle,
      'bs-pagination': BSPagination,
      'bs-table-no-data': BSTableNoData,
      'bs-filter-textfield': BSFilterTextfield,
      'bs-discriminator-reset': BSDiscriminatorReset,
      // 'bs-unique-items-select': BSUniqueItemsSelect,
      'bs-table-unique-select': BSTableUniqueSelect,
      'bs-datetime-range-picker': BSDatetimeRangePicker,
    },
    mixins: [
      PermissionsMixin,
      BSDatetimeRangePickerMixin,
    ],
    data() {
      return {
        // selectedTableItems: [],
        isIndeterminate: false,
        selectAllCheckboxes: false,

        // sortIcon: 'mdi-chevron-up',
        sortIcon: 'mdi-chevron-up-circle',

        // sortDesc: false,
        defaultPagination: {
          page: 1,
          perPage: 20,
          pages: 0,
          total: this.items.length,
          // total: 0,
          search: null,
          sortDirection: 'ascending',
          sortColumn: null,
        },
        paginationString: '',
        filteredPagination: {},
        headerProps: {
          sortIcon: 'mdi-menu-up',
        },
        footerProps: {
          itemsPerPageOptions: [
            // 5,
            10, 20, 50, 100, -1,
          ],
        },
      }
    },
    watch: {
      items: {
        deep: false,
        immediate: false,
        handler(newValue, oldValue) {
          // console.log('watch items oldValue', oldValue)
          // console.log('watch items newValue', newValue)
          // console.log('watch items computedDefaultPagination', this.computedDefaultPagination)
          this.computedDefaultPagination.total = this.items.length
          // console.log('watch items computedDefaultPagination', this.computedDefaultPagination)

        },
      },
      selectedTableItems: {
        deep: false,
        handler(newValue, oldValue) {
          // console.log('selectedTableItems oldValue', oldValue)
          // console.log('selectedTableItems newValue', newValue)

          if (this.selectedTableItems.length === 0) {
            // console.log('selectAll NONE SELECTED')
            this.isIndeterminate = false
            this.selectAllCheckboxes = false
          } else if (
              this.computedTableItemsSelectable.length ===
              this.selectedTableItems.length
          ) {
            // console.log('selectAll ALL SELECTED')
            this.isIndeterminate = false
            this.selectAllCheckboxes = true
          } else {
            // console.log('selectAll INDETERMINATE')
            this.isIndeterminate = true
            this.selectAllCheckboxes = false
          }
        },
      },
    },
    computed: {
      computedDatetimeModel: {
        get() {
          // console.log('computedDatetimeModel get')
          let datetimeArray = null

          // ub specific
          const startDatetime = this.localDiscriminators.startDatetime
          const endDatetime = this.localDiscriminators.endDatetime
          // const startDatetime = this.computedEntriesDiscriminators.startDatetime
          // const endDatetime = this.computedEntriesDiscriminators.endDatetime
          // console.log('computedDatetimeModel startDatetime', startDatetime)
          // console.log('computedDatetimeModel endDatetime', endDatetime)
          // ub specific

          let startDatetimeObject = parseISO(startDatetime)
          let endDatetimeObject = parseISO(endDatetime)
          // console.log('localValue startDatetimeObject', startDatetimeObject)
          // console.log('localValue endDatetimeObject', endDatetimeObject)

          if (isValid(startDatetimeObject) && isValid(endDatetimeObject)) {
            datetimeArray = [
              startDatetimeObject,
              endDatetimeObject,
            ]
          }

          return datetimeArray
        },
        set(datetimeArray) {
          // console.log('computedDatetimeModel setdatetimeArray', datetimeArray)

          let startDatetime = datetimeArray[0]
          let endDatetime = datetimeArray[1]

          let startDatetimeIso = startDatetime ? formatISO(startDatetime) : null
          let endDatetimeIso = endDatetime ? formatISO(endDatetime) : null
          // console.log('localValue set update:startDatetime', startDatetimeIso)
          // console.log('localValue set update:endDatetime', endDatetimeIso)

          // ub specific
          this.localDiscriminators.startDatetime = startDatetimeIso
          this.localDiscriminators.endDatetime = endDatetimeIso
          // this.computedEntriesDiscriminators.startDatetime = startDatetimeIso
          // this.computedEntriesDiscriminators.endDatetime = endDatetimeIso
          // ub specific

        },
      },

      computedTableItems: function() {
        // console.log('computedTableItems')
        let itemsComputed = this.items
        // console.log('computedTableItems itemsComputed', itemsComputed)

        this.computedFilterHeaders.forEach((header) => {
          // console.log('computedTableItems computedFilterHeaders header', header)
          if (header._filter === undefined) {
            // console.log('computedTableItems _filter undefined', header.value)

            let discriminatorValue = this.discriminators[header.value]
            // console.log('computedTableItems _filter undefined discriminatorValue', discriminatorValue, header.value)

            if (header.filterType === 'select') {
              // console.log('computedTableItems _filter select computedTableItems discriminatorValue', discriminatorValue)

              // console.log('_filter select computedFilterHeaders header.value', header.value)
              itemsComputed = itemsComputed.filter((item) => {
                let headerValue = item[header.value]
                // console.log('_filter select computedTableItems headerValue', headerValue)
                // console.log('computedTableItems _filter undefined select item[header.value]', header.value, discriminatorValue, item[header.value])

                return (
                    // this.discriminators[header.value] === null ||
                    // item[header.value] === this.discriminators[header.value]

                    // discriminatorValue === null ||
                    // item[header.value] === discriminatorValue ||
                    discriminatorValue === null ||
                    discriminatorValue === undefined ||
                    item[header.value] === discriminatorValue
                )
              })
              // console.log('computedTableItems itemsComputed', itemsComputed)
              // console.log('computedTableItems _filter undefined select itemsComputed', itemsComputed, header.value)

            } else if (header.filterType === 'dateRange') {
              // console.log('computedTableItems _filter undefined dateRange', header.value, discriminatorValue)
              // let discriminatorValue = this.discriminators[header.value]
              // console.log('computedTableItems _filter dateRange computedTableItems discriminatorValue', discriminatorValue)

              if (discriminatorValue) {
                itemsComputed = itemsComputed.filter((item) => {
                  let headerValue = header.value
                  // console.log('computedTableItems _filter dateRange computedTableItems headerValue', headerValue)
                  // console.log('computedTableItems _filter dateRange item[header.value]', header.value, discriminatorValue, item[header.value])

                  let itemValue = item[headerValue]
                  // console.log('computedTableItems _filter dateRange computedTableItems itemValue', itemValue)

                  return !itemValue || this.datetimeInRange(itemValue, discriminatorValue)
                })

              }
              // console.log('computedTableItems _filter dateRange itemsComputed', itemsComputed, header.value)

            }
          } else {
            // console.log('computedTableItems _filter header._filter', header.value)
            itemsComputed = itemsComputed.filter(header._filter)
            // console.log('computedTableItems _filter header._filter itemsComputed', itemsComputed, header.value)

          }
        })
        // console.log('computedTableItems itemsComputed', itemsComputed)

        return itemsComputed
      },

      computedDefaultPagination: {
        get() {
          const defaultPagination = this.defaultPagination
          // console.log('computedDefaultPagination get defaultPagination', defaultPagination)
          return defaultPagination
        },
        set(value) {
          // console.log('computedDefaultPagination set value', value)
          this.defaultPagination = value
        },
      },

      computedShowSelect: function() {
        // console.log('computedShowSelect')
        // let retVal
        // console.log('computedShowSelect', this.$attrs)
        // const showSelectProp = get(this.$attrs, 'show-select')
        return this.showSelect
      },
      computedShowSelectAll: function() {
        // console.log('computedShowSelectAll')
        // let retVal
        // console.log('computedShowSelectAll', this.$attrs)
        // const showSelectAllProp = get(this.$attrs, 'show-select-all')
        // return (showSelectAllProp === true)
        return this.showSelectAll
      },
      selectedTableItems: {
        get() {
          // console.log('computed localFormModel get value', this.value)
          return this.value
        },
        set(value) {
          // console.log('computed localFormModel set value', value)
          this.$emit('input', value)
        },
      },

      computedTableContainerClasses: function() {
        // console.log('computedTableContainerClasses')

        let classes = []
        if (this.debug && this.computedIsDevelopment) {
          classes.push('debug')
        }
        if (this.hasHeader === false) {
          classes.push('no-header')
        }
        return classes
      },
      computedTableItemsSelectable: function() {
        // console.log('_filter computedTableItems')
        let itemsComputed = this.computedTableItems
        // console.log('_filter computedTableItems itemsComputed', itemsComputed)
        itemsComputed = itemsComputed.filter((item) => {
          return item.isSelectable === true
        })
        // console.log('_filter computedTableItems itemsComputed', itemsComputed)

        return itemsComputed
      },

      // TODO: new style for v-model props
      // TODO: new hotness for v-model props
      localDiscriminators: {
        get() {
          return this.discriminators
        },
        set(value) {
          // this.$emit('input', value)
          // console.log('localDiscriminators value', value)
        },
      },

      computedHasActionColumn: function() {
        // console.log('computedHasActionColumn')
        let retVal = this.headers.find((object) => object.value === 'actions')
        // console.log('computedHasActionColumn', retVal)
        return retVal !== undefined
      },
      computedHeaders: function() {
        let headers = this.headers
        // console.log('computedHeaders', headers)
        if (this.hasContentForSlotName('tableActions') === true && this.computedHasActionColumn === false) {
          headers = [
            ...headers,
            {
              text: '',
              value: 'tableActionHeader',
              sortable: false,
              filterable: false,
              discriminable: false,
              align: 'start',
              class: [ 'min' ],
              cellClass: [],
            },
          ]
        }

        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        // set provided header class to an array if it isnt already
        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        headers = headers.map((object) => {
          if (typeof object.class == 'string') {
            object.class = [ object.class ]
          }
          return object
        })
        // console.log('computedHeaders headers typeof', headers)

        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        // start dont show display === false cols
        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        /*
         headers = headers.filter(header => {
         return header.display !== false
         })
         */
        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        // end dont show display === false cols
        /////////////////////////////////////////////////////////////////////////////////////////////////////////

        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        // start remove headers for role if prefered
        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        if (this.roleHeaders) {
          headers = this.headersForAccess(headers)
        }
        // console.log('computedHeaders headersForAccess', headers)
        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        // end remove headers for role if prefered
        /////////////////////////////////////////////////////////////////////////////////////////////////////////

        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        // start set header classes
        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        // console.log('computedHeaders headers start', headers)

        headers = headers.map((header) => {
          /////////////////////////////////////////////////////////////////////////////////////////////////////////
          // start set header classes
          /////////////////////////////////////////////////////////////////////////////////////////////////////////
          let textAlign = `text-${ header.align || 'start' }`
          let filterable = header.filterable
          let sortable = header.sortable

          header.class = [ ...header.class, textAlign ]
          if (filterable) {
            header.class = [ ...header.class, 'filterable' ]
          }
          if (sortable) {
            header.class = [ ...header.class, 'sortable' ]
          }
          // set cell classes
          header.cellClass = [ ...header.cellClass, textAlign ]

          return header
        })

        // console.log('computedHeaders headers after', headers)
        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        // end set header classes
        /////////////////////////////////////////////////////////////////////////////////////////////////////////

        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        // start set sort classes
        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        headers = this.sortableHeaders(headers)
        // console.log('computedHeaders highlightSort', headers)
        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        // end set sort classes
        /////////////////////////////////////////////////////////////////////////////////////////////////////////

        return headers
      },
      computedFilterHeaders: function() {
        let headers = this.computedHeaders
        // console.log('computedFilterHeaders this.computedHeaders', this.computedHeaders)
        headers = headers.filter(
            (header) => header.filterable === true && [ 'select', 'selectMultiple', 'dateRange' ].includes(header.filterType),
        )
        return headers
      },
      computedFilterableHeaders: function() {
        // console.log('computedFilterableHeaders')
        let headers = this.computedFilterHeaders
        // let filterableHeaders = this.headers.filter(object => object.filterable === true)
        headers = headers.map((object) => {
          return object.value
        })
        return headers
      },

      computedTableClasses: function() {
        // console.log('computedTableClasses')
        // highlightSort
        // roleHeaders
        // rowHover
        // altRows

        let classes = {
          'bs-table': true,
          'bs-highlight-sort': this.highlightSort,
          'bs-role-headers': this.roleHeaders,
          'bs-row-hover': this.rowHover,
          'bs-alt-rows': this.altRows,
        }

        let elevationClass
        if (this.elevation > 0) {
          elevationClass = `elevation-${ this.elevation }`
          classes = {
            ...classes,
            [elevationClass]: true,
          }
        }
        return classes
      },
      computedPaginationString: function() {
        // console.log('computedPaginationString')
        let retVal
        let defaultPagination = this.computedDefaultPagination

        if (defaultPagination.perPage === -1) {
          retVal = `1-${ defaultPagination.total } of ${ defaultPagination.total }`
        } else {
          let pageOffset = defaultPagination.perPage - 1
          // console.log('computedPaginationString pageOffset', pageOffset)

          let firstItemNumber =
              defaultPagination.page * defaultPagination.perPage - pageOffset
          // console.log('computedPaginationString firstItemNumber', firstItemNumber)

          let lastItemNumber = firstItemNumber + pageOffset
          // console.log('computedPaginationString lastItemNumber', lastItemNumber)

          let totalCount = defaultPagination.total
          let totalCountString = ''
          if (totalCount) {
            totalCountString = ` of ${ defaultPagination.total }`
          }

          // let totalCountString = ` of ${this.total}`

          lastItemNumber =
              lastItemNumber > defaultPagination.total
                  ? defaultPagination.total
                  : lastItemNumber
          // console.log('computedPaginationString lastItemNumber', lastItemNumber)

          retVal = `${ firstItemNumber }-${ lastItemNumber }${ totalCountString }`
        }
        return retVal
      },

      computedAttrs: function() {
        const attrs = { ...this.$attrs }
        attrs.class = this.$vnode.data.staticClass
        attrs.style = this.$vnode.data.staticStyle
        return attrs
      },
    },

    methods: {
      hasContentForSlotName: function(slot) {
        let userContent = !!this.$slots[slot]
        let search = this.search === slot
        let autoDiscriminators = this.autoDiscriminators === slot
        return userContent || search || autoDiscriminators
      },
      /*
       slotHasContent: function(slot) {
       let userContent = !!this.$slots[slot]
       let search = this.search === slot
       let autoDiscriminators = this.autoDiscriminators === slot
       return userContent || search || autoDiscriminators
       },
       */

      setPaginationString: function(paginationString) {
        // console.log('setPaginationString paginationString', paginationString)
        this.$emit('changed:paginationString', paginationString)
        this.paginationString = paginationString
      },

      btnSelectAll: function() {
        // console.log('btnSelectAll')
        if (this.selectAllCheckboxes === true) {
          // console.log('selectAllCheckboxes newValue true')
          this.selectedTableItems = this.computedTableItemsSelectable
          // this.selectedTableItems = this.computedTableItems
        } else {
          this.selectedTableItems = []
        }
        this.$emit('click:select-all', this.selectAllCheckboxes)
      },

      hasColumnDiscriminators: function() {
        // console.log('hasColumnDiscriminators')
        return this.computedFilterableHeaders.some(
            (header) => !!this.$slots[header],
        )
      },
      sortableHeaders: function(headers) {
        // console.log('sortableHeaders', headers)
        return headers.map((header) => {
          let sortBy = this.computedDefaultPagination.sortColumn
          let sortDirection = this.computedDefaultPagination.sortDirection

          let classSet = new Set(header.class)
          let cellClassSet = new Set(header.cellClass)

          classSet.delete('sorted')
          cellClassSet.delete('sorted')

          classSet.delete('ascending')
          classSet.delete('descending')

          cellClassSet.delete('ascending')
          cellClassSet.delete('ascending')

          if (sortBy === header.value) {
            classSet.add('sorted')
            cellClassSet.add('sorted')

            classSet.add(sortDirection)
            cellClassSet.add(sortDirection)
          }

          header.class = [ ...classSet ]
          header.cellClass = [ ...cellClassSet ]

          return header
        })
      },
      headersForAccess: function(headers) {
        // console.log('headersForAccess')

        return headers.filter((object) => {
          // console.log('0 HeadersForAccess:', object.value)

          let retVal = true

          let hasRole = Object.hasOwn(object, 'role')
          let hasScope = Object.hasOwn(object, 'scope')
          let hasAccess = Object.hasOwn(object, 'access')

          if (hasRole || hasScope || hasAccess) {
            if (hasRole) {
              // console.log('1 HeadersForAccess role specified:', object.value, object.role, hasRole)
              retVal = this.userHasRequiredRole(object.role)
            } else if (hasScope || hasAccess) {
              // console.log('2 HeadersForAccess scope specified:', object.value, object.scope, hasScope)
              // console.log('2 HeadersForAccess access specified:', object.value, object.access, hasAccess)
              if (hasScope && hasAccess) {
                retVal = this.userHasRequiredScopeAndAccess(
                    object.scope,
                    object.access,
                )
              } else if (hasScope) {
                retVal = this.userHasRequiredScope(object.scope)
              } else if (hasAccess) {
                retVal = this.userHasRequiredAccess(object.access)
              }
            }
          } else {
            // header does not specify perms
          }
          return retVal
        })

        // return headers.filter(object => {
        //   let retVal = true
        //   let hasRole = Object.hasOwn(object, 'roles')
        //   if (hasRole) {
        //     retVal = this.userHasRequiredRole(object.roles)
        //   }
        //   return retVal
        // })
      },

      setSortColumn: function(header) {
        // console.log('setSortColumn header', header)
        // console.log('setSortColumn value', header.value)
        let oldSortColumn = this.computedDefaultPagination.sortColumn
        let newSortColumn = header.value

        let oldSortDirection = this.computedDefaultPagination.sortDirection
        let newSortDirection

        if (oldSortDirection === 'ascending') {
          newSortDirection = 'descending'
        } else if (oldSortDirection === 'descending') {
          newSortDirection = 'ascending'
        }

        if (oldSortColumn === newSortColumn) {
          this.computedDefaultPagination.sortDirection = newSortDirection
        } else {
          this.computedDefaultPagination.sortColumn = newSortColumn
        }
      },
      getSortIcon: function(header) {
        // console.log('SortIcon header', header)
        let icon
        let sortDirection = this.computedDefaultPagination.sortDirection

        if (header.sortable === true) {
          icon = '$sortSolid'
          if (header.class.includes('sorted')) {
            if (sortDirection === 'ascending') {
              icon = 'mdi-chevron-up-circle'
            } else if (sortDirection === 'descending') {
              icon = 'mdi-chevron-up-circle'
            }
          }
        }
        return icon
      },
      optionsDidChange: function(event) {
        // console.log('optionsDidChange event', event)
      },
      paginationDidChange: function(event) {
        // console.log('paginationDidChange event', event)
        this.filteredPagination = event
      },
      sortChanged($event, context) {
        // console.log('sortChanged $event', $event)
        // console.log('sortChanged context', context)
        if (context === 'sortColumn') {
          this.computedDefaultPagination.sortColumn = $event
        } else if (context === 'sortDirection') {
          let retVal
          if ($event === true) {
            retVal = 'descending'
          } else if ($event === false) {
            retVal = 'ascending'
          }
          this.computedDefaultPagination.sortDirection = retVal
        }
      },
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {
      // console.log('items', this.items)
      // console.log('mounted $slots', this.$slots)
      // console.log('mounted $scopedSlots', this.$scopedSlots)
    },
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped></style>
