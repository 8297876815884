<!--
Copyright ThreatBlockr Inc 2023
Created by ejohnson on 12/6/23

// https://github.com/mengxiong10/vue2-datepicker.git
-->
<template>

  <!--:placeholder=computedPlaceholder-->
  <!--:confirm="confirm"-->

  <!--:default-value="[ computedOldest, computedNewest ]"-->

  <datepicker v-model="localValue"
              style="width: 100%"
              value-type="YYYY-MM-DDTHH:mm:ssZ"
              format="MM/DD/YY, hh:mm a"


              :disabled-date="computedDisabledDate"
              :disabled-time="computedDisabledTime"

              :append-to-body="true"
              type="datetime"
              input-class="header-discriminator header-daterange bs-table-daterange"

              time-title-format="MM/DD/YY, hh:mm a"

              confirm
              :show-time-panel="showTimePanel"
              :class="{ 'bs-field-dirty': computedIsDirty }"
              v-bind="$attrs"
              v-on="$listeners"

  >
    <!--
                  v-bind="$attrs"
              v-on="$listeners"

    v-bind="{ ...$attrs, ...computedAttributes }"
    -->

    <!--
    <template v-slot:input="slotProps">
      <v-text-field
          :disabled="$attrs.disabled || loading"
          :loading="loading"
          clear-icon="mdi-close-circle"

          :outlined="true"
          :solo="true"
          :flat="true"
          :dense="true"

          single-line
          clearable
          hide-details
          filled

          v-bind="{ ...slotProps.props, ...textFieldProps }"
          v-on="slotProps.events">
        <template v-if="showGlyph"
                  v-slot:prepend-inner>
          <v-icon class="mr-1">mdi-calendar-clock</v-icon>
        </template>
      </v-text-field>
    </template>
    -->
    <template v-slot:input="slotProps">

      <v-text-field v-bind="{ ...slotProps.props, ...textFieldProps }"
                    :disabled="$attrs.disabled || loading"
                    :loading="loading"
                    clear-icon="mdi-close-circle"

                    hide-details="auto"

                    v-on="slotProps.events">
        <template v-if="showGlyph"
                  v-slot:prepend-inner>
          <v-icon class="mr-1">mdi-calendar-clock</v-icon>
        </template>
      </v-text-field>

      <!--<bs-print :value="{slotProps}"/>-->
      <!--<bs-print :value="{textFieldProps}"/>-->

    </template>

    <template v-slot:icon-calendar>
      <v-icon class=""></v-icon>
    </template>
    <template v-slot:icon-clear>
      <v-icon class="">mdi-close-circle</v-icon>
    </template>
    <template v-slot:footer="{ emit }">
      <v-btn-toggle v-model="showTimePanel"
                    dense
                    mandatory>

        <v-btn :value="false">
          <v-icon>mdi-calendar-blank-outline</v-icon>
        </v-btn>
        <v-btn :value="true">
          <v-icon>mdi-clock-outline</v-icon>
        </v-btn>
      </v-btn-toggle>

    </template>

  </datepicker>

</template>

<script>
  // https://github.com/mengxiong10/vue2-datepicker/issues/671
  // https://github.com/mengxiong10/vue2-datepicker/issues/584
  // https://github.com/mengxiong10/vue2-datepicker/issues/616
  // https://github.com/mengxiong10/vue2-datepicker/issues/457 https://codesandbox.io/p/sandbox/open-time-panel-8wm3g     detect click date vs time

  import DatePicker from 'vue2-datepicker'
  import 'vue2-datepicker/index.css'

  import { getDayOfYear, isFuture, isPast, isToday, isWithinInterval, parseISO } from 'date-fns'
  import { first, isEqual, last, orderBy } from 'lodash'

  export default {
    inheritAttrs: true,

    name: 'BSBaseDatetimePicker',
    props: {
      // https://vuejs.org/guide/components/props.html#prop-validation

      showGlyph: {
        type: Boolean,
        default: false,
      },

      datetimeProperty: {
        default: 'datetime',
      },

      textFieldProps: {
        type: Object,
        default: function() {
          return {}
        },
      },
      value: {
        type: [ String, Array ],
        default: null,
      },
      items: {
        type: Array,
        required: false,
        default(rawProps) {
          return []
        },
      },

      disabledDate: {
        type: Function,
      },
      disabledTime: {
        type: Function,
      },
      futureOnly: {
        type: Boolean,
        default: false,
      },
      pastOnly: {
        type: Boolean,
        default: false,
      },

      loading: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        showTimePanel: false,
        default: null,
      }
    },
    components: {
      'datepicker': DatePicker,
    },
    mixins: [
      // StickyDiscriminatorMixin,
      // BSDatetimeRangePickerMixin,
    ],
    watch: {},
    computed: {
      computedAttributes: function() {
        // <!--:default-value="[ computedOldest, computedNewest ]"-->

        // console.log('computedAttributes')
        // let hasValidator = this.validator !== null && this.validator !== undefined
        // console.log('computedValidationAttributes this.validator', this.validator)
        // console.log('computedValidationAttributes hasValidator', hasValidator)

        let retVal
        if (this.items.length) {
          retVal = {
            defaultValue: [ this.computedOldest, this.computedNewest ],
          }
        }
        return retVal
      },

      // TODO: new style for v-model props
      // TODO: new hotness for v-model props
      localValue: {
        get() {
          return this.value
        },
        set(val) {
          this.$emit('input', val)
        },
      },

      computedDisabledDate: function() {
        // console.log('computedDisabledDate ////////////////////////////////')
        let retVal = this.disabledDate
        // console.log('computedDisabledDate retVal', retVal)
        if (this.futureOnly) {
          // console.log('computedDisabledDate futureOnly')
          retVal = this.isDatePastFunction
        } else if (this.pastOnly) {
          // console.log('computedDisabledDate pastOnly', this.pastOnly)
          retVal = this.isDateFutureFunction
          // console.log('computedDisabledDate pastOnly retVal', retVal())
        } else if (this.items.length) {
          console.log('computedDisabledDate items.length', this.items.length)
          retVal = this.dateOutsideFunc
          // console.log('computedDisabledDate items.length retVal', retVal())
        } else {
          // console.log('computedDisabledDate fall thru')
        }
        // console.log('================================== computedDisabledDate retVal', retVal)
        return retVal
      },
      computedDisabledTime: function() {
        // console.log('computedDisabledTime ////////////////////////////////')
        let retVal = this.disabledDate
        // console.log('computedDisabledTime retVal', retVal)

        if (this.futureOnly) {
          // console.log('computedDisabledTime futureOnly')
          retVal = this.isTimePastFunction
        } else if (this.pastOnly) {
          retVal = this.isTimeFutureFunction
          // retVal = this.pastTimeOnlyFunc
        } else if (this.items.length) {
          retVal = this.timeOutsideFunc
          // retVal = this.betweenDatesFunc
        }
        return retVal
      },

      // start dynamic blackout /////////////////////////////////////////////////////
      // start dynamic blackout /////////////////////////////////////////////////////
      // start dynamic blackout /////////////////////////////////////////////////////
      computedOldest: function() {
        // console.log('computedOldest ///////////////////////////////////////////////')
        // console.log('computedOldest this.items', this.items)

        let sortedArray = this.computedOrderedItems
        console.log('bookends computedOldest sortedArray', sortedArray)

        let row = first(sortedArray)
        // console.log('computedOldest row', row)

        if (!row) return
        let datetime = row[this.datetimeProperty]
        // let datetime = row.datetime
        // console.log('===================================== bookends computedOldest datetime', datetime)

        return datetime
      },
      computedNewest: function() {
        // console.log('computedNewest ///////////////////////////////////////////////')
        // console.log('computedNewest this.items', this.items)

        let sortedArray = this.computedOrderedItems
        console.log('bookends computedNewest sortedArray', sortedArray)

        let row = last(sortedArray)
        // console.log('computedNewest row', row)

        if (!row) return
        let datetime = row[this.datetimeProperty]
        // let datetime = row.datetime
        // console.log('bookends computedNewest datetime', datetime)

        console.log('===================================== bookends computedNewest datetime', datetime)
        return datetime
      },
      // end dynamic blackout /////////////////////////////////////////////////////
      // end dynamic blackout /////////////////////////////////////////////////////
      // end dynamic blackout /////////////////////////////////////////////////////

      computedOrderedItems: function() {
        // console.log('computedOrderedItems')

        let items = this.items
        items = items.filter(object => {
          return object[this.datetimeProperty] !== null && object[this.datetimeProperty] !== undefined
        })

        // console.log('computedOrderedItems items', items)

        let sortedArray = orderBy(items, [ this.datetimeProperty ], [ 'asc' ])
        // let sortedArray = orderBy(items, [ 'datetime' ], [ 'asc' ])
        // console.log('computedOrderedItems sortedArray', sortedArray)

        return sortedArray
      },
      computedIsDirty: function() {
        // console.log('computedIsDirty')
        //                :class="{ 'discriminator-dirty': this.localValue !== this.default }"
        // console.log('computedIsDirty this.localValue', this.localValue)
        // console.log('computedIsDirty this.default', this.default)

        const equal = isEqual(this.localValue, this.default)
        // console.log('computedIsDirty equal', equal)

        const isDirty = equal !== true
        // const isDirty = this.localValue !== this.default
        // console.log('computedIsDirty isDirty', isDirty)
        // console.log('computedIsDirty ///////////////////////////////////////////////////')

        // return true
        return isDirty
      },
    },
    methods: {
      // start dynamic blackout /////////////////////////////////////////////////////
      // start dynamic blackout /////////////////////////////////////////////////////
      // start dynamic blackout /////////////////////////////////////////////////////
      dateOutsideFunc: function(datetime) {
        // console.log('dateOutsideFunc datetime', datetime)
        let retVal = !this.dateBetweenFunc(datetime)
        return retVal
      },
      dateBetweenFunc: function(datetime) {
        console.log('dateBetweenFunc datetime //////////////////////////////////////////', datetime)
        let datetimeType = typeof datetime
        // console.log('dateBetweenFunc datetimeType', datetimeType)

        const consideredDate = getDayOfYear(datetime)
        console.log('dateBetweenFunc consideredDate', consideredDate)

        let startDatetimeString = this.computedOldest
        let endDatetimeString = this.computedNewest
        console.log('dateBetweenFunc startDatetimeString', startDatetimeString)
        console.log('dateBetweenFunc endDatetimeString', endDatetimeString)

        const startDatetime = parseISO(startDatetimeString)
        const endDatetime = parseISO(endDatetimeString)
        // console.log('dateBetweenFunc startDatetime', startDatetime)
        // console.log('dateBetweenFunc endDatetime', endDatetime)

        const startDatetimeDayOfYear = getDayOfYear(startDatetime)
        const endDatetimeDayOfYear = getDayOfYear(endDatetime)
        // console.log('dateBetweenFunc startDatetimeDayOfYear', startDatetimeDayOfYear)
        // console.log('dateBetweenFunc endDatetimeDayOfYear', endDatetimeDayOfYear)

        const dayOfYearIsAfter = consideredDate >= startDatetimeDayOfYear
        const dayOfYearIsBefore = consideredDate <= endDatetimeDayOfYear

        let shouldShowDay = dayOfYearIsAfter && dayOfYearIsBefore
        // console.log('========================= dateBetweenFunc shouldShowDay', shouldShowDay)

        return shouldShowDay
      },

      ////////////////////////////////
      ////////////////////////////////
      ////////////////////////////////

      timeOutsideFunc: function(datetime) {
        // console.log('dateOutsideFunc')
        return !this.timeBetweenFunc(datetime)
      },
      timeBetweenFunc: function(datetime) {
        // console.log('timeBetweenFunc internal datetime', datetime)

        const startDatetimeString = this.computedOldest
        const endDatetimeString = this.computedNewest
        const startDatetime = parseISO(startDatetimeString)
        const endDatetime = parseISO(endDatetimeString)
        // console.log('timeBetweenFunc internal startDatetime', startDatetime)
        // console.log('timeBetweenFunc internal endDatetime', endDatetime)

        const interval = [ startDatetime, endDatetime ]
        let isDatetimeInRange = this.datetimeInRange(datetime, interval)
        // console.log('timeBetweenFunc internal isDatetimeInRange', isDatetimeInRange)

        // console.log('timeBetweenFunc internal /////////////////////////////////////////////////')

        return isDatetimeInRange
      },
      datetimeInRange: function(datetime, datetimeRange) {
        // console.log('datetimeInRange datetime /////////////////////////////////////////')
        // console.log('datetimeInRange datetime', datetime)
        // console.log('datetimeInRange datetimeRange', datetimeRange)

        if (!datetimeRange.length) return true
        const startDatetime = datetimeRange[0]
        const endDatetime = datetimeRange[1]
        // console.log('datetimeInRange startDatetime', startDatetime)
        // console.log('datetimeInRange endDatetime', endDatetime)

        let interval = {
          start: startDatetime,
          end: endDatetime,
        }
        // console.log('datetimeInRange interval', interval)

        let isWithinIntervalBool = isWithinInterval(datetime, interval)

        // console.log('$$$ datetimeInRange isWithinIntervalBool')
        // console.log('datetimeInRange isWithinIntervalBool datetime', datetime)
        // console.log('datetimeInRange isWithinIntervalBool interval', interval)
        // console.log('datetimeInRange isWithinIntervalBool', isWithinIntervalBool)
        // console.log('%%% datetimeInRange isWithinIntervalBool')

        // console.log('datetimeInRange internal isWithinIntervalBool interval.start', interval.start)
        // console.log('datetimeInRange internal isWithinIntervalBool interval.end', interval.end)
        // console.log('datetimeInRange internal isWithinIntervalBool', isWithinIntervalBool)
        // console.log('datetimeInRange internal isWithinIntervalBool ////////////////////////////////')
        // console.log('=============================================== datetimeInRange')

        return isWithinIntervalBool
      },



      // end dynamic blackout /////////////////////////////////////////////////////
      // end dynamic blackout /////////////////////////////////////////////////////
      // end dynamic blackout /////////////////////////////////////////////////////
      isTimePastFunction: function(datetime) {
        // console.log('isTimePastFunction datetime', format(datetime, 'aaa HH:mm:ss:SSSS'))

        let comparison
        comparison = isPast(datetime)

        // console.log('isTimePastFunction comparison', comparison)
        // console.log('isTimePastFunction //////////////////////////////////////////////////////////////')

        return comparison
      },
      isTimeFutureFunction: function(datetime) {
        // console.log('isTimeFutureFunction datetime', format(datetime, 'aaa HH:mm:ss:SSSS'))
        // console.log('isTimeFutureFunction datetime', format(datetime, 'MM/dd/yyyy HH:mm:ss:SSSS aaa'))

        let comparison
        comparison = isFuture(datetime)

        // console.log('isTimeFutureFunction comparison', comparison)
        // console.log('isTimeFutureFunction //////////////////////////////////////////////////////////////')

        return comparison
      },
      isDatePastFunction: (datetime) => {
        // console.log('isDatePastFunction datetime', datetime)

        let comparison
        const dateTimeIsToday = isToday(datetime)
        if (dateTimeIsToday) {
          comparison = false
        } else {
          comparison = isPast(datetime)
        }

        // console.log('isDatePastFunction comparison', comparison)
        // console.log('isDatePastFunction //////////////////////////////////////////////////////////////')

        return comparison
      },
      isDateFutureFunction: (datetime) => {
        // console.log('isDateFutureFunction datetime', datetime)

        let comparison
        const dateTimeIsToday = isToday(datetime)
        if (dateTimeIsToday) {
          comparison = false
        } else {
          comparison = isFuture(datetime)
        }

        // console.log('isDateFutureFunction comparison', comparison)
        // console.log('isDateFutureFunction //////////////////////////////////////////////////////////////')

        return comparison
      },
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  /*
  .success {
    background-color: #1d7c66 !important;
    border-color: #1d7c66 !important;
  }
  */
</style>
