const state = () => ({
    articlesNew: [
        {
            identifier: 'commandLog',
            href: 'https://support.threater.com/hc/en-us/articles/12821534574356-Command-Logs',
            tooltip: 'Command Log Documentation',
            scopes: [
                'CMP',
            ],
        },
    ],

    articles: {
        commandLog: {
            href: 'https://support.threater.com/hc/en-us/articles/12821534574356-Command-Logs',
            tooltip: 'Command Log Documentation',
            scopes: [
                // 'FREE',
                // 'RMS',
                // 'MSP',
                'CMP',
            ],
        },
        user: {
            href: 'https://support.threater.com/hc/en-us/articles/12708978969748-Users',
            tooltip: 'User Documentation',
            scopes: [
                'CMP',
            ],
        },
        product: {
            href: 'https://support.threater.com/hc/en-us/articles/12703869840404-Accessing-Marketplace-Intelligence-Feeds',
            tooltip: 'Marketplace Documentation',
            scopes: [
                'CMP',
            ],
        },
        report: {
            href: 'https://support.threater.com/hc/en-us/articles/12799181041812-Reports',
            tooltip: 'Report Documentation',
            scopes: [
                'CMP',
            ],
        },
        subscription: {
            href: 'https://support.threater.com/hc/en-us/articles/12886361476116-Subscriptions',
            tooltip: 'Subscription Documentation',
            scopes: [
                'CMP',
            ],
        },
        port: {
            href: 'https://support.threater.com/hc/en-us/articles/12882216759956-Ports',
            tooltip: 'Port Documentation',
            scopes: [
                'CMP',
            ],
        },
        instance: {
            href: 'https://support.threater.com/hc/en-us/articles/12863842494612-Manage-and-Update-Enforce-Software',
            tooltip: 'Enforcer Documentation',
            scopes: [
                'CMP',
            ],
        },


        list: {
            href: 'https://support.threater.com/hc/en-us/sections/4403465758996-Collect',
            create: 'https://support.threater.com/hc/en-us/sections/4403465758996-Collect',
            tooltip: 'Collect Documentation',
            scopes: [
                'CMP',
            ],
        },
        network: {
            href: 'https://support.threater.com/hc/en-us/articles/12881333750548-Networks',
            create: 'https://support.threater.com/hc/en-us/articles/12881333750548-Networks#creatingNetworks',
            tooltip: 'Network Documentation',
            scopes: [
                'CMP',
            ],
        },
        policy: {
            href: 'https://support.threater.com/hc/en-us/articles/12834233349780-Policies',
            create: 'https://support.threater.com/hc/en-us/articles/12834233349780-Policies#CreatingaPolicy',
            tooltip: 'Policy Documentation',
            scopes: [
                'CMP',
            ],
        },
        ub: {
            href: 'https://support.threater.com/hc/en-us/articles/24169750617492-Unexpected-Blocks',
            tooltip: 'Unexpected Block Documentation',
            scopes: [
                'CMP',
            ],
        },
    },
})

// this.$store.getters['kbArticles/articles']
const getters = {
    articles: (state, getters) => {
        return state.articles
    },

}

// $store.dispatch('auth/authRedirect')
const actions = {}

// $store.commit('auth/SET_JWT', response.data)
const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}

