<template>

  <!--<div>-->
  <v-btn v-if="tooltipPosition === 'none'"
         :type="type"
         :color="computedPresetLookup.color"
         exact
         icon
         :class="computedPresetLookup.classes"
         v-bind="$attrs"
         v-on="$listeners">

    <v-icon size="">{{ computedPresetLookup.icon }}</v-icon>
  </v-btn>

  <v-tooltip v-else
             :[tooltipPosition]="true">
    <template v-slot:activator="{ on, attrs }">
      <v-btn :class="computedPresetLookup.classes"
             :type="type"
             exact
             icon
             :color="computedPresetLookup.color"
             v-bind="$attrs"
             v-on="{...on, ...$listeners}">

        <v-icon>{{ computedPresetLookup.icon }}</v-icon>

      </v-btn>
    </template>
    <span>{{ computedPresetLookup.tooltip }}</span>
  </v-tooltip>

  <!--</div>-->

</template>

<script>
  export default {
    name: 'BSGlyphButton',
    inheritAttrs: false,
    props: {
      // proppy https://vuejs.org/guide/components/props.html#prop-validation

      type: {
        type: String,
        default: 'button',
        // required: true,
      },
      icon: {
        type: String,
        default: 'mdi-help-circle-outline',
      },
      color: {
        type: String,
        default: 'normal',
      },
      tooltip: {
        type: String,
        // default: '_DEFAULT_TOOLTIP_',
      },
      tooltipPosition: {
        type: String,
        default: 'top',
        validator: function(value) {
          return [ 'top', 'right', 'bottom', 'left', 'none' ].includes(value)
        },
      },

      preset: {
        type: String,
      },

      classes: {
        type: Object,
        // Object or array defaults must be returned from
        // a factory function. The function receives the raw
        // props received by the component as the argument.
        default(rawProps) {
          return {
            'bl-icon-button': true,
          }
        },
      },
      /*
       classes: {
       type: Array,
       // Object or array defaults must be returned from
       // a factory function. The function receives the raw
       // props received by the component as the argument.
       default(rawProps) {
       return [
       'bl-icon-button',
       ]
       },
       },
       */
      options: {
        type: Object,
      },
      /*
       options: {
       type: Object,
       // Object or array defaults must be returned from
       // a factory function. The function receives the raw
       // props received by the component as the argument.
       default(rawProps) {
       return {}
       },
       },
       */

      /*
       hoverShow: {
       type: Boolean,
       default: false,
       },
       */

      dark: {
        type: Boolean,
        default: false,
      },
      muted: {
        type: Boolean,
      },
    },
    data() {
      return {
        presets: {
          columns: {
            icon: 'mdi-view-column-outline',
            tooltip: this.tooltip || 'Toggle Columns',
            classes: {
              'bl-icon-button': true,
              'slate--text': !this.muted,
            },
          },
          mitigate: {
            icon: 'mdi-crosshairs-gps',
            hoverIcon: 'mdi-crosshairs',
            tooltip: this.tooltip || 'Mitigate...',
            classes: {
              'bl-icon-button': true,
              'bl-icon-button-edit': true,
              'info--text': !this.muted,
            },
          },
          tear: {
            icon: 'mdi-rounded-corner',
            hoverIcon: 'mdi-close-circle',
            tooltip: this.tooltip || 'Tear Off',
            classes: {
              'bl-icon-button-dark': this.dark,
              'bl-icon-button': !this.dark,
              'bl-icon-button-tear': true,
            },
          },
          close: {
            icon: 'mdi-close',
            hoverIcon: 'mdi-close-circle',
            tooltip: this.tooltip || 'Close',
            classes: {
              'bl-icon-button-dark': this.dark,
              'bl-icon-button': !this.dark,
              'bl-icon-button-close': true,
            },
          },
          menu: {
            icon: 'mdi-dots-vertical',
            tooltip: this.tooltip || 'Actions',
            classes: {
              'bl-icon-button': true,
              'slate--text': !this.muted,
            },
          },
          deleteCircleOutline: {
            icon: 'mdi-delete-circle-outline',
            color: 'danger',
            tooltip: this.tooltip || 'Delete',
            classes: {
              'bl-icon-button': true,
              'bl-icon-button-delete': true,
              'danger--text': !this.muted,
            },
          },
          delete: {
            icon: 'mdi-delete-outline',
            color: 'danger',
            tooltip: this.tooltip || 'Delete',
            classes: {
              'bl-icon-button': true,
              'bl-icon-button-delete': true,
              'danger--text': !this.muted,
            },
          },
          circleEditOutline: {
            icon: 'mdi-circle-edit-outline',
            color: this.muted ? null : 'info',
            tooltip: this.tooltip || 'Edit',
            classes: {
              'bl-icon-button': true,
              'bl-icon-button-edit': true,
            },
          },
          edit: {
            icon: 'mdi-pencil-outline',
            color: 'info',
            tooltip: this.tooltip || 'Edit',
            classes: {
              'bl-icon-button': true,
              'bl-icon-button-edit': true,
              'info--text': !this.muted,
            },
          },
          swap: {
            icon: 'mdi-arrow-left-right-bold-outline',
            color: 'info',
            tooltip: this.tooltip || 'Swap',
            classes: {
              'bl-icon-button': true,
              'bl-icon-button-swap': true,
              'info--text': !this.muted,
            },
          },
          schedule: {
            icon: 'mdi-calendar-month-outline',
            color: 'info',
            tooltip: this.tooltip || 'Scheduled',
            classes: {
              'bl-icon-button': true,
              'bl-icon-button-create': true,
              'info--text': !this.muted,
            },
          },
          save: {
            icon: 'mdi-content-save-outline',
            color: 'success',
            tooltip: this.tooltip || 'Save',
            classes: {
              'bl-icon-button': true,
              'bl-icon-button-create': true,
              'success--text': !this.muted,
            },
          },
          create: {
            icon: 'mdi-plus-circle-outline',
            color: 'success',
            tooltip: this.tooltip || 'Create',
            classes: {
              'bl-icon-button': true,
              'bl-icon-button-create': true,
              'success--text': !this.muted,
            },
          },
          download: {
            icon: 'mdi-text-box-outline',
            color: 'info',
            tooltip: this.tooltip || 'Download',
            classes: {
              'bl-icon-button': true,
              'bl-icon-button-edit': true,
              'info--text': !this.muted,
            },
          },
          export: {
            icon: 'mdi-export',
            tooltip: this.tooltip || 'Export',
            classes: {
              'bl-icon-button': true,
              'bl-icon-button-edit': true,
              'info--text': !this.muted,
            },
          },
          add: {
            icon: 'mdi-playlist-play',
            color: 'success',
            tooltip: this.tooltip || 'Add',
            classes: {
              'bl-icon-button': true,
              'bl-icon-button-add': true,
              'success--text': !this.muted,
            },
          },
        },
      }
    },
    components: {},
    mixins: [],
    watch: {},
    computed: {
      /*
       computedType: function() {
       // console.log('computedType')
       let retVal = 'button'
       if ([ 'submit' ].includes(this.type)) {
       retVal = this.type
       }
       return retVal
       },
       */
      computedPresetLookup: function() {
        // console.log('computedPresetLookup')
        let options

        let optionsDefault = {
          type: this.type,
          icon: this.icon,
          color: this.color,
          tooltip: this.tooltip,
          tooltipPosition: this.tooltipPosition,
          classes: {
            ...this.classes,
          },
          // classes: this.classes,
          /*
           classes: [
           'bl-icon-button',
           (this.hoverShow ? 'hover-show' : null),
           ],
           */
        }
        let preset = this.presets[this.preset]
        let optionsProp = this.options

        console.log('computedPresetLookup optionsDefault', optionsDefault)
        console.log('computedPresetLookup preset', preset)
        console.log('computedPresetLookup optionsProp', optionsProp)

        options = {
          ...optionsDefault,
          ...preset,
        }
        console.log('computedPresetLookup merged preset', options)

        options = {
          ...options,
          ...optionsProp,
        }
        console.log('computedPresetLookup merged optionsProp', options)
        console.log('computedPresetLookup ////////////////////////')

        return options
      },
      computedLookup: function() {
        // console.log('computedLookup')

        const type = this.type
        // console.log('computedLookup type', type)
        const color = this.color
        // console.log('computedLookup color', color)
        const computedPassedClasses = this.computedPassedClasses
        // console.log('computedLookup computedPassedClasses', computedPassedClasses)

        let options
        let optionsDefault = {
          classes: [
            ...computedPassedClasses,
            'bl-icon-button',
            // (this.hoverShow ? 'hover-show' : null),
            // (this.muted ? '' : 'success--text'),
          ],
          icon: 'mdi-help-circle-outline',
          color: this.color,
          tooltip: '_DEFAULT_TOOLTIP_',
        }
        // console.log('computedLookup optionsDefault', optionsDefault)

        if (this.type && this.type !== 'submit') {
          // options = this.lookup[type]
          options = {
            ...optionsDefault.classes,
            ...this.presets[type],
          }
          // console.log('computedLookup type', type, options)
        } else if (this.options) {
          options = {
            ...optionsDefault.classes,
            ...this.options,
          }
          // console.log('computedLookup options', type, options)
        } else {
          options = optionsDefault
          // console.log('computedLookup type else', type, options)
        }
        // console.log('computedLookup type return', type, options)
        // console.log('computedLookup ////////////////////////////////////////////////////////////////')

        return options
      },
      computedPassedClasses: function() {
        // console.log('computedPassedClasses')

        let classArray = []
        const staticClass = this.$vnode.data.staticClass
        // console.log('computedPassedClasses staticClass', staticClass)

        if (staticClass) {
          classArray = staticClass.split(' ')
        }
        // console.log('computedPassedClasses classArray', classArray)

        return classArray
      },
      computedAttrs: function() {
        const attrs = { ...this.$attrs }
        attrs.class = this.$vnode.data.staticClass
        attrs.style = this.$vnode.data.staticStyle
        // console.log('computedAttrs attrs', attrs)

        return attrs
      },
    },
    methods: {},
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

  :deep(.v-icon).v-icon::after {
    border-radius: 0;

    // background-color: currentColor;
    // border-radius: 50%;
    // content: '';
    // display: inline-block;
    // height: 100%;
    // left: 0;
    // opacity: 0;
    // pointer-events: none;
    // position: absolute;
    // top: 0;
    // transform: scale(1.3);
    // width: 100%;
    // transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  }
</style>
